// src/components/cards/card.js

import React from 'react';
import './card.css';

// Import all images from the 'assets/images' folder
const images = require.context('../../assets/images', false, /\.(jpg|jpeg|png|gif)$/);

const Card = ({ title, description }) => {
  // Function to get a random image from the list of imported images
  const getRandomImage = () => {
    const imageArray = images.keys();
    const randomIndex = Math.floor(Math.random() * imageArray.length);
    return images(imageArray[randomIndex]);
  };

  return (
    <div className="card">
      <h3>{title}</h3>
      <div className="image-container">
        <img className="card-image" src={getRandomImage()} alt={title} />
      </div>
      <p>{description}</p>
    </div>
  );
};

export default Card;
