import React from 'react';

function Home() {
  return (
    <div className="main-content">
      <h1>Welcome to My Portfolio</h1>
      <p>This is the home page where you can learn more about my work and skills.</p>
    </div>
  );
}

export default Home;
