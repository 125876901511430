import React from 'react';

function SkillSets() {
  return (
    <div className="main-content">
      <h1>My Skill Sets</h1>
      <ul>
        <li>JavaScript</li>
        <li>React</li>
        <li>Node.js</li>
        <li>HTML/CSS</li>
      </ul>
    </div>
  );
}

export default SkillSets;
