// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

// Import Components
import Header from './components/header';
import Home from './components/home';
import Portfolio from './components/portfolio';
import SkillSets from './components/skillSets';
import ContactUs from './components/contactUs';
import About from './components/about';
import Footer from './components/footer'; 

function App() {
  return (
    <Router>
      <Header />
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/skillsets" element={<SkillSets />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </div>
      <Footer /> 
    </Router>
          
  );
}

export default App;
