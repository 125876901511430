// src/components/footer.js
import React, { useState, useEffect } from 'react';
import './footer.css';

const Footer = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Update time every second

    return () => clearInterval(timer); // Cleanup timer
  }, []);

  return (
    <footer className="footer">
      <p>
        &copy; {currentTime.getFullYear()} Your Website. All Rights Reserved.
      </p>
      <p>
        {currentTime.toLocaleDateString()} | {currentTime.toLocaleTimeString()}
      </p>
    </footer>
  );
};

export default Footer;
