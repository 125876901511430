import React from 'react';

function About() {
  return (
    <div className="main-content">
      <h1>About Me</h1>
      <p>I'm a web developer passionate about building modern applications using the latest technologies.</p>
    </div>
  );
}

export default About;
