// src/components/scripts.js
import React from 'react';
import Card from './cards/card'; // Import the Card component

const Portfolio = () => {
  const projects = [
    {
      title: 'Project 1',
      description: 'Description of Project 1',
      imageUrl: 'https://via.placeholder.com/150', // Placeholder image URL
    },
    {
      title: 'Project 2',
      description: 'Description of Project 2',
      imageUrl: 'https://via.placeholder.com/150', // Placeholder image URL
    },
    {
      title: 'Project 3',
      description: 'Description of Project 3',
      imageUrl: 'https://via.placeholder.com/150', // Placeholder image URL
    },
    {
      title: 'Project 4',
      description: 'Description of Project 4',
      imageUrl: 'https://via.placeholder.com/150', // Placeholder image URL
    },
  ];

  return (
    <div className="portfolio">
      <h2>My Portfolio</h2>
      <div className="portfolio-cards">
        {projects.map((project, index) => (
          <Card
            key={index}
            title={project.title}
            description={project.description}
            imageUrl={project.imageUrl} // Pass image URL to Card component
          />
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
