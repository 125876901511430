import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet'; // Import leaflet for custom icons
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [location, setLocation] = useState({
    lat: 39.8283,  // Default to the United States (central point)
    lng: -98.5795,
  });
  
  // Get the user's current location using the Geolocation API
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ lat: latitude, lng: longitude }); // Update state with user's actual location
        },
        (error) => {
          console.error("Error getting location: ", error);
          // Optionally, handle error here (fallback to default location)
        }
      );
    }
  }, []);  // Empty dependency array ensures this runs only once when component mounts

  // Handle input changes for form fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic for sending form data can be added here
    alert('Form submitted successfully!');
  };

  // Define a custom icon for the marker
  const customIcon = new L.Icon({
    iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/e/ec/RedDot.svg',  // You can replace this URL with any custom icon URL
    iconSize: [32, 32], // Size of the icon
    iconAnchor: [16, 32], // The point of the icon which will correspond to the marker's location
    popupAnchor: [0, -32], // The point from which the popup will open
  });

  return (
    <div className="contactUs">
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            required
          ></textarea>
        </div>

        <button type="submit">Submit</button>
      </form>

      {/* Leaflet Map */}
      <div className="map-container">
        <MapContainer
          center={location}  // This centers the map on the user's location or defaults to the US if unavailable
          zoom={5}           // Zoom level (lower value means more zoomed out)
          style={{ width: '100%', height: '100%' }} // Ensure the map takes up full space of the container
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={location} icon={customIcon}>
            <Popup>
              You are here!
            </Popup>
          </Marker>
        </MapContainer>
      </div>
    </div>
  );
};

export default ContactUs;
